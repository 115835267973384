import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import PaginationComponent from "../../Components/Pagination";
import { formatDateToText } from "../../Helpers/Utilities";
import { createCarRental, editeCarRental } from "../Core/Rotes";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PAGE_SIZE = 10;

export default function CarRentals() {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [loader, showLoader, hideLoader] = useLoader();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    // Fetch countries
    const getCountries = useCallback(async () => {
        try {
            const response = await fetch(baseurl + "country/get-countries", {
                method: "GET"
            });
            const json = await response.json();
            if (json && Array.isArray(json)) {
                setCountries(json);
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    }, []);

    useEffect(() => {
        getCountries();
    }, [getCountries]);

    const getData = useCallback(async (pageNo = 1, textSearch = "") => {
        showLoader();
        const formdata = new FormData();
        formdata.append("page", pageNo);
        formdata.append("totalSize", PAGE_SIZE);
        formdata.append("textSearch", textSearch);
        formdata.append("countryId", selectedCountry);

        try {
            const response = await fetch(baseurl + "dashboard/get-car-rentals", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formdata,
            });
            const json = await response.json();
            hideLoader();
            setTotalPages(json.totalPages);
            setData(json);
            return json;
        } catch (error) {
            hideLoader();
            console.error(error);
        }
    }, [selectedCountry, showLoader, hideLoader]);

    // Initial data load
    useEffect(() => {
        getData(1, searchText);
    }, []); 

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getData(page, searchText);
    };

    const handleSearch = () => {
        setCurrentPage(1);
        getData(1, searchText);
    };

    const handleFilter = () => {
        setCurrentPage(1);
        getData(1, searchText);
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleDeleteClick = (id) => {
        setSelectedItemId(id);
        setOpenDeleteDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedItemId(null);
    };

    const handleConfirmDelete = async () => {
        showLoader();
        try {
            const formData = new FormData();
            formData.append("id", selectedItemId);

            const response = await fetch(baseurl + "car-rental/delete-car-rental", {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formData,
            });

            if (response.ok) {
                // Refresh the data after successful deletion
                getData(currentPage, searchText);
            } else {
                console.error("Error deleting car rental");
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            hideLoader();
            handleCloseDialog();
        }
    };

    return (
        <div>
            {loader}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">السيارات</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="/#">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                السيارات
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <Link to={createCarRental} className="btn btn-primary">
                        إضافة سيارة جديدة
                    </Link>
                </div>
            </div>
            <hr />

            <div className="card">
                <div className="card-body">
                    <div className="row mb-3 align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="d-flex gap-2">
                                <input
                                    autoComplete="off"
                                    onChange={(e) => setSearchText(e.target.value)}
                                    value={searchText}
                                    name="SearchData"
                                    className="form-control"
                                    type="search"
                                    placeholder="بحث في القائمة"
                                    style={{
                                        height: '45px',
                                        fontSize: '1rem',
                                        borderRadius: '8px',
                                        backgroundColor: '#f8f9fa',
                                        border: '1px solid #ced4da'
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                />
                                <button
                                    onClick={handleSearch}
                                    className="btn btn-primary"
                                    type="submit"
                                    style={{
                                        height: '45px',
                                        minWidth: '80px',
                                        fontSize: '1rem',
                                        borderRadius: '8px'
                                    }}
                                >
                                    بحث
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <FormControl fullWidth>
                                <InputLabel 
                                    id="country-select-label" 
                                    sx={{ 
                                        color: '#6c757d',
                                        '&.Mui-focused': {
                                            color: '#0d6efd'
                                        }
                                    }}
                                >
                                    الدولة
                                </InputLabel>
                                <Select
                                    labelId="country-select-label"
                                    value={selectedCountry}
                                    label="الدولة"
                                    onChange={handleCountryChange}
                                    sx={{
                                        height: '45px',
                                        backgroundColor: '#f8f9fa',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#ced4da'
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#0d6efd'
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#0d6efd'
                                        }
                                    }}
                                >
                                    <MenuItem value={0} sx={{ color: '#495057' }}>الكل</MenuItem>
                                    {countries.map((country) => (
                                        <MenuItem 
                                            key={country.id} 
                                            value={country.nameAr}
                                            sx={{ 
                                                color: '#495057',
                                                '&:hover': {
                                                    backgroundColor: '#e9ecef'
                                                }
                                            }}
                                        >
                                            {country.nameAr}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-2">
                            <button 
                                className="btn btn-primary w-100" 
                                onClick={handleFilter}
                                style={{
                                    height: '45px',
                                    fontSize: '1rem',
                                    borderRadius: '8px'
                                }}
                            >
                                تصفية
                            </button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th className="text-center align-middle">م</th>
                                    <th className="text-center align-middle">اللوجو</th>
                                    <th className="text-center align-middle">الاسم</th>
                                    <th className="text-center align-middle">السعر</th>
                                    <th className="text-center align-middle">وقت البداية</th>
                                    <th className="text-center align-middle">وقت النهاية</th>
                                 
                                    <th className="text-center align-middle">العمليات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.items?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center align-middle">{item.id}</td>
                                        <td className="text-center align-middle">
                                            <img
                                                src={baseurl+"/images/"+item.logo}
                                                alt={item.name}
                                                className="rounded-circle"
                                                style={{ width: '64px', height: '64px', objectFit: 'cover' }}
                                            />
                                        </td>
                                        <td className="text-center align-middle">{item.nameAr}</td>
                                        <td className="text-center align-middle">{item.price}</td>
                                        <td className="text-center align-middle">{formatDateToText(item.startDay)}</td>
                                        <td className="text-center align-middle">{formatDateToText(item.endDay)}</td>
                                     
                                        <td className="text-center align-middle" style={{ minWidth: '200px' }}>
                                            <div className="d-flex align-items-center justify-content-center gap-1">
                                                <Link
                                                    to="/admin/carRentals/details"
                                                    state={{ row: item }}
                                                    className="btn btn-sm btn-success px-2"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    عرض
                                                </Link>
                                                <Link
                                                    to={createCarRental}
                                                    state={{ row: item }}
                                                    className="btn btn-sm btn-primary px-2"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    تعديل
                                                </Link>
                                                <button
                                                    className="btn btn-sm btn-danger px-2"
                                                    onClick={() => handleDeleteClick(item.id)}
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    حذف
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {data && (
                            <nav aria-label="Page navigation example">
                                <PaginationComponent
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </nav>
                        )}
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                dir="rtl"
                PaperProps={{
                    sx: {
                        width: '400px',
                        borderRadius: '12px',
                        p: 1
                    }
                }}
            >
                <DialogTitle 
                    id="alert-dialog-title"
                    sx={{
                        textAlign: 'center',
                        color: '#dc3545',
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                        pb: 1
                    }}
                >
                    تأكيد الحذف
                </DialogTitle>
                <DialogContent sx={{ pb: 3 }}>
                    <DialogContentText 
                        id="alert-dialog-description"
                        sx={{
                            textAlign: 'center',
                            color: '#495057',
                            fontSize: '1.1rem'
                        }}
                    >
                        هل أنت متأكد من حذف هذا العنصر؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions 
                    sx={{ 
                        justifyContent: 'center',
                        gap: 2,
                        pb: 2 
                    }}
                >
                    <button 
                        onClick={handleCloseDialog} 
                        className="btn btn-secondary"
                        style={{
                            minWidth: '100px',
                            fontSize: '1rem'
                        }}
                    >
                        إلغاء
                    </button>
                    <button 
                        onClick={handleConfirmDelete} 
                        className="btn btn-danger"
                        style={{
                            minWidth: '100px',
                            fontSize: '1rem'
                        }}
                        autoFocus
                    >
                        حذف
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}