import React from "react";

import { useNavigate } from 'react-router-dom';
import ExternalScripts from './ExternalScripts';
import { addOnboardingAdmin, createBrannd, createCarRental, createCountry, createData, createModel, onboarding } from '../Pages/Core/Rotes';


export default function Sidebar() {

  const navigateTo = useNavigate()

  return <aside className="sidebar-wrapper" data-simplebar="true">

    <ExternalScripts />

    <div className="sidebar-header">
      <div>
        <img src="../images/logo-icon.png" className="logo-icon rounded-circle" alt="logo icon" />
      </div>
      <div>
        <h4 className="logo-text">Transit App</h4>
      </div>
      <div className="toggle-icon ms-auto"> <i class='bx bx-menu' ></i>
      </div>
    </div>
    {/*navigation*/}
    <ul className="metismenu" id="menu">
      <li>
        <a onClick={e => navigateTo("/admin/home")} aria-expanded="true">
          <div className="parent-icon"><i className="bi bi-house-fill" />
          </div>
          <div className="menu-title">الرئيسية</div>
        </a>
      </li>

      {/* categories  */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-grid-1x2" />
          </div>
          <div className="menu-title">الخدمات</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/services")}> <a ><i className="bi bi-circle" />قائمة الخدمات</a>
          </li>
          {/* <li onClick={e=>navigateTo("/admin/Service/add")}> <a><i className="bi bi-circle" />إضافة خدمة</a>
       </li> */}
        </ul>
      </li>

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-grid-fill" />
          </div>
          <div className="menu-title">الاقسام</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/categories")}> <a ><i className="bi bi-circle" />قائمة الاقسام</a>
          </li>
          <li onClick={e => navigateTo("/admin/category/add")}> <a><i className="bi bi-circle" />إضافة قسم</a>
          </li>
        </ul>
      </li>


      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-shop-window" />
          </div>
          <div className="menu-title">المتاجر</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/markets")}> <a ><i className="bi bi-circle" />قائمة المتاجر</a>
          </li>
          <li onClick={e => navigateTo("/admin/market/add")}> <a><i className="bi bi-circle" />إضافة متجر</a>
          </li>
        </ul>
      </li>


      {/* data  */}

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-database"></i>
          </div>
          <div className="menu-title"> البيانات</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/data")}> <a ><i className="bi bi-circle" />قائمة البيانات</a>
          </li>
          <li onClick={e => navigateTo(createData)}> <a><i className="bi bi-circle" />إضافة بيانات</a>
          </li>
        </ul>
      </li>
      {/* cars RENTAL  */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-car-front-fill"></i>
          </div>
          <div className="menu-title">  ايجار السيارت</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/carRentals")}> <a ><i className="bi bi-circle" />قائمة السيارت</a>
          </li>
        
        </ul>
      </li>




      {/* brands  */}

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-car-front-fill"></i>
          </div>
          <div className="menu-title">  ماركات السيارت</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/brands")}> <a ><i className="bi bi-circle" />قائمة الماركات</a>
          </li>
          <li onClick={e => navigateTo(createBrannd)}> <a><i className="bi bi-circle" />إضافة ماركة</a>
          </li>
        </ul>
      </li>


      {/* models */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-car-front-fill"></i>
          </div>
          <div className="menu-title">  مودل السيارت</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/models")}> <a ><i className="bi bi-circle" />قائمة المودلات</a>
          </li>
          <li onClick={e => navigateTo(createModel)}> <a><i className="bi bi-circle" />إضافة مودل</a>
          </li>
        </ul>
      </li>
      {/* countries */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-flag"></i>
          </div>
          <div className="menu-title"> الدول</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/countries")}> <a ><i className="bi bi-circle" />قائمة الدول</a>
          </li>
          <li onClick={e => navigateTo(createCountry)}> <a><i className="bi bi-circle" />إضافة دولة</a>
          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-bookmark-star" />
          </div>
          <div className="menu-title">الاعلانات</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/banners")}> <a ><i className="bi bi-circle" />قائمة الاعلانات</a>
          </li>
          <li onClick={e => navigateTo("/admin/banner/add")}> <a><i className="bi bi-circle" />إضافة اعلان</a>
          </li>
        </ul>
      </li>








      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-qr-code-scan"></i>
          </div>
          <div className="menu-title">الطلبات</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/orders")}> <a><i className="bi bi-circle" />قائمة الطلبات </a>
          </li>

        </ul>
      </li>


      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-people"></i>
          </div>
          <div className="menu-title">المستخدمين</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/users")}> <a><i className="bi bi-circle" />قائمة المستخدمين</a>
          </li>
          <li onClick={e => navigateTo("/admin/user/roles")}> <a><i className="bi bi-circle" />إدارة أدوار المستخدمين</a>
          </li>
          <li onClick={e => navigateTo("/admin/agents")}> <a><i className="bi bi-circle" />قائمة الوكلاء</a>
          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-gear" />
          </div>
          <div className="menu-title">الاعدادات</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/Sittings")}> <a ><i className="bi bi-circle" />قائمة الاعدادات</a>
          </li>
          <li> <a href="/admin/Setting/add"><i className="bi bi-circle" />إضافة اعداد</a>
          </li>
        </ul>
      </li>

      {/* الصور الترحيبية */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className="bi bi-gear" />
          </div>
          <div className="menu-title">الصور الترحيبية </div>
        </a>
        <ul>
          <li onClick={e => navigateTo(onboarding)}> <a ><i className="bi bi-circle" /> الصور الترحيبية </a>
          </li>
          <li> <a href={addOnboardingAdmin}><i className="bi bi-circle" />إضافة صورة</a>
          </li>
        </ul>
      </li>


      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-bell"></i>
          </div>
          <div className="menu-title">الاشعارات</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/alerts")}> <a ><i className="bi bi-circle" />قائمة الاشعارات</a>
          </li>
          <li> <a href="/admin/alert/add"><i className="bi bi-circle" />ارسال اشعار</a>
          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i class="bi bi-archive"></i>
          </div>
          <div className="menu-title">الشكاوي والاقتراحات</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/suggestions")}> <a ><i className="bi bi-circle" />قائمة الشكاوي</a>
          </li>

        </ul>
      </li>


      {/* <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bx bxs-package" />
       </div>
       <div className="menu-title">الباقات</div>
     </a>
     <ul>
       <li> <a href="/Packages/Index"><i className="bi bi-circle" />قائمة الباقات</a>
       </li>
       <li> <a href="/Packages/Create"><i className="bi bi-circle" />إضافة باقة</a>
       </li>
     </ul>
   </li> */}



      {/*
   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bx bxs-bell" />
       </div>
       <div className="menu-title">الإشعارات</div>
     </a>
     <ul>
       <li onClick={e=>navigateTo("/admin/notifs")}> <a ><i className="bi bi-circle" />قائمة الإشعارات</a>
       </li>
       <li onClick={e=>navigateTo("/admin/notif/add")}> <a ><i className="bi bi-circle" />إشعار إلي مستخدم</a>
       </li>
     </ul>
   </li> */}

      <li>
        <a onClick={e => {
          localStorage.clear()
          navigateTo("/")
        }} aria-expanded="true">
          <div className="parent-icon"><i class='bx bxs-exit'></i>
          </div>
          <div className="menu-title">تسجيل الخروج</div>
        </a>
      </li>

      {/*end navigation*/}
    </ul></aside>
}

