import { toast } from "react-hot-toast";
import axios from "axios";
export const baseurl = "https://apinawt.urapp.site/"

  // export const baseurl = "http://localhost:50330/";
export const onInputInvalid = (e) =>
  e.target.setCustomValidity("هذا الحقل مطلوب");

export const OnInput = (e) => e.target.setCustomValidity("");

export const uploadImage = async (file) => {
  const formdata = new FormData();
  formdata.append("file", file);
  try {
    const response = await fetch(baseurl + "image/upload/image", {
      method: "POST",
      body: formdata,
    });
    const json = await response.json();
    console.error(json.image + "image");
    return json.image;
  } catch (error) {
    console.error(error + "error");
  }
};

export const uploadImageHatli = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const resp = await axios
      .post(baseurl + "image/upload/image", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((e) => {
        console.log(e.data);

        return e.data;
      });
  } catch (e) {
    console.log(e);
  }
};

export const successNotify = () => toast.success("تمت العملية بنجاح");
export const errorNotify = () =>
  toast.error("هناك خطأ في البيانات الرجاء التأكد وإعادة المحاولة");
export const errorNotifyMessage = (message) => toast.error(message);


export const orderStatues = [
  {
    "nameAr": "تم الاستلام",
    "id": 0

  },
  {
    "nameAr": "تم استلام الشحنة",
    "id": 1

  },

  {
    "nameAr": "تم الشحن",
    "id": 2
  },
  {
    "nameAr": "تم وصول ميناء الترانزيت",
    "id": 3
  },
  {
    "nameAr": "تم وصول ميناء بلد المقصد",
    "id": 4
  },
  {
    "nameAr": "تم التخليص الجمركي",
    "id": 5
  },
  {
    "nameAr": "تم تسليم العميل",
    "id": 6
  },

];
/* 
   // type data const

        public const string YearManufactor = "Year Manufactor";
        public const string CarCategory = "Car Category";

        public const string EngineType = "Engine Type";
        public const string EngineCapacity = "Engine Capacity";
        public const string Cars = "Cars";
        public const string Engines = "Engines";

        public const string ParcelShippment = "parcel Shippment";

       public const string MotorcylceType = "Motorcylce type";

         public const string ContainerType =  "ContainerType";
*/

//! entry types 
export const entryTypes = [


  {
    "nameAr": "سنة الصنع",
    "nameEn": "Year Manufactor",
    
  },


  {
    "nameAr": "فئة السيارة",
    "nameEn": "Car Category",
  },

  {
    "nameAr": "نوع المحرك",
    "nameEn": "Engine Type",
  },

  {
    "nameAr": "سعة المحرك",
    "nameEn": "Engine Capacity",
  },
  {
    "nameAr": " سعة المحرك للدراجة",
    "nameEn": "Engine Capacity moto",
  },
  {
    "nameAr": "السيارات",
    "nameEn": "Cars",
  },
  {
    "nameAr": "المحركات",
    "nameEn": "Engines",
  },

  {
    "nameAr": "شحن الطرود",
    "nameEn": "parcel Shippment",
  },

  {
    "nameAr": "نوع الدراجة",
    "nameEn": "Motorcylce type",
  },

  {
    "nameAr": "نوع الحاويات",
    "nameEn": "Container Type",
  },


];
