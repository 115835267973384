import React from "react";

const PaginationComponent = ({ totalPages, currentPage, onPageChange }) => {
    const maxVisiblePages = 5; // عدد الصفحات التي تظهر في نفس الوقت

    const getVisiblePages = () => {
      if (totalPages <= maxVisiblePages) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      }
  
      const half = Math.floor(maxVisiblePages / 2);
      let start = Math.max(currentPage - half, 1);
      let end = start + maxVisiblePages - 1;
  
      if (end > totalPages) {
        end = totalPages;
        start = end - maxVisiblePages + 1;
      }
  
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };
  
    const visiblePages = getVisiblePages();
  
    return (
      <nav>
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              السابق
            </button>
          </li>
  
          {visiblePages.map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? "active" : ""}`}
            >
              <button className="page-link" onClick={() => onPageChange(page)}>
                {page}
              </button>
            </li>
          ))}
  
          {visiblePages[visiblePages.length - 1] < totalPages && (
            <>
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => onPageChange(totalPages)}
                >
                  {totalPages}
                </button>
              </li>
            </>
          )}
  
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              التالي
            </button>
          </li>
        </ul>
      </nav>
    );
  };

export default PaginationComponent;
