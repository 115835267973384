import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import useLoader from '../../Components/loader/useLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseurl } from '../../constants';
import RowDetails from '../../Components/RowDetails';
import { formatDateToText, getServices, listStatusOrder, listStatusOrderFurniture } from '../../Helpers/Utilities';
import Lable from '../../Components/Lable';
import PrintableComponent from './PrintOrder';
import ReactToPrint from "react-to-print";

const OrderDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, status } = location.state.row;
    const componentRef = useRef();
    const [data, setData] = useState(null);
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [loader, showLoader, hideLoader] = useLoader();

    // ! get agents list
    const getAgents = async () => {
        try {
            const response = await fetch(baseurl + "dashboard/get-agents", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            const json = await response.json();
            setAgents(json);
            return json;
        } catch (error) {
            console.error("Error fetching agents:", error);
        }
    };

    // ! get order details 
    const getOrderDetails = async (id) => {
        showLoader();
        var requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        fetch(baseurl + 'orders/get-Orders-details?orderId=' + id, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                hideLoader();
                console.log(result);
                setData(result);
                return result;
            })
            .catch((error) => {
                hideLoader();
                console.log("error", error);
            });
    }

    // ! update order Staues
    const updateStatus = async (orderId, status) => {
        showLoader();
        try {
            var formdata = new FormData();
            formdata.append("orderId", orderId);
            formdata.append("status", status);

            const response = await fetch(baseurl + "dashboard/update-status-order", {
                body: formdata,
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            const json = await response.json();
            console.log(json);
            hideLoader();
            return json;
        } catch (error) {
            hideLoader();
            console.error(error + "errrrrrrrr");
        }
    };

    // ! send order to agent
    const sendOrderToAgent = async () => {
        if (!selectedAgent) {
            alert("الرجاء اختيار وكيل");
            return;
        }

        showLoader();
        try {
            var formdata = new FormData();
            formdata.append("orderId", id);
            formdata.append("agentId", selectedAgent);

            const response = await fetch(baseurl + "dashboard/send-order-to-agent", {
                body: formdata,
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            const json = await response.json();
            console.log(json);
            alert("تم إرسال الطلب للوكيل بنجاح");
            hideLoader();
            return json;
        } catch (error) {
            hideLoader();
            console.error(error + "errrrrrrrr");
            alert("حدث خطأ أثناء إرسال الطلب للوكيل");
        }
    };

    useEffect(() => {
        console.log(id);
        if (data === null) {
            getOrderDetails(id);
            getAgents();
        }
    }, [])

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="content-wrapper">
            {loader}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">الطلبات</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="javascript:;">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                تفاصيل الطلب
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <hr />
            {data && (
                <>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap5">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p></p>
                                            <div className="row justify-content-start">
                                                <RowDetails name="رقم الطلب" value={data.order.id} />
                                                
                                                {/* Agent Assignment Section */}
                                                <div className="col-4 mb-3">
                                                    <label className="form-label">{"تعيين وكيل للطلب"} </label>
                                                    <div className="d-flex gap-2">
                                                        <select
                                                            required
                                                            onChange={(e) => setSelectedAgent(e.target.value)}
                                                            value={selectedAgent}
                                                            className="form-select"
                                                        >
                                                            <option value="">اختر الوكيل</option>
                                                            {agents.map((agent) => (
                                                                <option key={agent.id} value={agent.id}>
                                                                    {agent.firstName} {agent.lastName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <button
                                                            onClick={sendOrderToAgent}
                                                            className="btn btn-primary"
                                                        >
                                                            إرسال للوكيل
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* change status */}
                                                <div className="col-4">
                                                    <label className="form-label">{"تعديل حالة الطلب"} </label>
                                                    <select
                                                        required
                                                        onChange={async (value) => {
                                                            console.log(value.target.value + "wefwwvefd");
                                                            await updateStatus(data.order.id, value.target.value);
                                                            await getOrderDetails(id);
                                                        }}
                                                        id="status-id"
                                                        className="form-select"
                                                    >
                                                        <option value={""}>تعديل حالة الطلب </option>
                                                        {data.order.typeStatus === 0 ? listStatusOrder.map((row) => (
                                                            <option key={row.id} value={row.id}>
                                                                {row.name}
                                                            </option>
                                                        )) : listStatusOrderFurniture.map((row) => (
                                                            <option key={row.id} value={row.id}>
                                                                {row.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <RowDetails name="حالة الطلب" value={listStatusOrder[data.order.status].name} />
                                                <RowDetails name="تاريخ الطلب" value={formatDateToText(data.order.createdAt)} />

                                                <Lable text="معلومات العميل" />
                                                {data.user && <>
                                                    <RowDetails name="الاسم الاول" value={data.user.firstName} />
                                                    <RowDetails name="الاسم الثاني" value={data.user.lastName} />
                                                    <RowDetails name="رقم الهاتف" value={data.user.phoneNumber} />
                                                    <RowDetails name="البريد الالكتروني" value={data.user.userName} />
                                                </>}
                                                <Lable text=" معلومات الطلب" />

                                              
                                                {data.dataEntries.map(e => <RowDetails name={e.typeAr} value={e.nameAr} />)}
                                                <RowDetails name="نوع الدراجة النارية" value={data.order.motoType} />
                                                {<RowDetails name="الماركة" value={data.brand && data.brand.nameAr} />}
                                                <RowDetails name="الموديل" value={data.model && data.model.nameAr} />

                                                {data.order.isOwner && <RowDetails name="هل انت المالك" value={data.order.isOwner && data.order.isOwner ? "نعم" : "لا"} />}
                                                <RowDetails name="نوع المركبة" value={data.order.carType} />
                                                <RowDetails name="نوع الشحن" value={data.order.pakageTypeShipping} />
                                                <RowDetails name="نوع التأمين" value={data.order.insrance} />

                                                <RowDetails name="الوصف" value={data.order.desc} isDesc={true} />
                                                <RowDetails name="الوزن" value={data.order.wight} />
                                                <RowDetails name="المودل" value={data.order.heavyModel} />
                                                <RowDetails name="نوع التأمين" value={data.order.insrance} />
                                                <RowDetails name="سعة المجرك" value={data.order.engineCapacityMoto} />

                                                <RowDetails name="عنوان الارسال " value={data.order.entryAddress} />
                                                <RowDetails name="عنوان الاستقبال" value={data.order.arrivalAddress} />
                                                <RowDetails name="بلد الارسال " value={data.sendingCountry && data.sendingCountry.nameAr} />
                                                <RowDetails name="بلد الاستقبال" value={data.arrivingCountry && data.arrivingCountry.nameAr} />
                                                <RowDetails name="بلد الإقامة" value={data.countryResidence && data.countryResidence.nameAr} />
                                                <RowDetails name="بلد المنشأ" value={data.countryOrigin && data.countryOrigin.nameAr} />

                                                {/* sizes  */}
                                                {data.order.large && <h5 className="my-3"> الاحجام المطلوبة</h5>}
                                                <RowDetails name="كبير" value={data.order.large} />
                                                <RowDetails name="متوسط" value={data.order.meduim} />
                                                <RowDetails name="صغير" value={data.order.small} />

                                                {/* service  */}
                                                {data.service && 
                                                <>
                                                 <RowDetails name="اسم الخدمة" value={data.service.nameAr} />
                                                 <RowDetails name="نوع التاجير" value={data.order.carRentalType} isDesc={true} />
                                                 <RowDetails name="نوع التملك" value={data.order.rentalType} />
                                                 <RowDetails name="وقت البداية" value={data.order.startDay} />
                                                 <RowDetails name="وقت النهاية" value={data.order.endDay} />

                                                 <RowDetails name="عدد السيارات" value={data.order.countCars} />
                                                 <RowDetails name="بريد الشركة"value={data.order.emailComppony} />
                                                 <RowDetails name="رقم هاتف الشركة" value={data.order.phoneComppony} />
                                                 <RowDetails name="CBM" value={data.order.cbmCount} />
                                                 

                                                 {data.order.optionsRental && (
                                                   <>
                                                     <h5 className="my-3">خدمات اضافية</h5>
                                                     {data.order.optionsRental.split('#').map((type, index) => (
                                                       <RowDetails key={index} name={`خدمة ${index + 1}`} value={type} />
                                                     ))}
                                                   </>
                                                 )}

                                                 {/* car details */}
                                                 {data.carRental && (
                                                   <>
                                                     <Lable text="معلومات السيارة" />
                                                {data.carRental && <>
                                                    <RowDetails name="اسم السيارة" value={data.carRental.nameAr} />
                                                    <RowDetails name="وصف السيارة" value={data.carRental.descAr} />
                                                    <RowDetails name="السعر" value={data.carRental.price} />
                                                    <RowDetails name="العملة" value={data.carRental.currency} />
                                                    {data.carRental.logo && (
                                                        <div className="mb-3">
                                                            <h5 className="text-primary">الصورة</h5>
                                                            <img
                                                                src={baseurl + "images/" + data.carRental.logo}
                                                                alt="Car"
                                                                onClick={() => window.open(baseurl + "images/" + data.carRental.logo, '_blank')}
                                                                style={{
                                                                    maxWidth: "300px",
                                                                    maxHeight: "200px", 
                                                                    objectFit: "cover",
                                                                    borderRadius: "8px",
                                                                    cursor: "pointer"
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                 
                                                </>}
                                                   </>
                                                 )}

                                                
                                                </>
                                                }

                                                {/* print  */}
                                                <div className="col-12">
                                                    <button
                                                        onClick={handlePrint}
                                                        className="btn btn-primary"
                                                    >
                                                        طباعة
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default OrderDetails

