import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import Switch from "@mui/material/Switch";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import ViewButton from "../../Components/Buttons/ViewButton";
import UpdateButton from "../../Components/Buttons/UpdateButton";
import { formatDateToText, getServices, listStatusOrder, listStatusOrderFurniture } from "../../Helpers/Utilities";
import { services } from "../../Helpers/Data";
import { deleteOrder, orderDetails } from "../Core/Rotes";

var count;
var search = "";
var source = [];

export default function Orders() {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [pagination, setPagination] = useState({});
    const [services, setServices] = useState();
    const [serviceId, setServiceId] = useState(0);





    const [loader, showLoader, hideLoader] = useLoader();






    const configurePagination = (recordCount, index) => {
        if (index < 1 || index > count) return;

        let pagination = {};
        let pageSize = 1;
        const total = count / pageSize;
        var totalPage = parseInt(total);
        if (total > totalPage) {
            totalPage = totalPage + 1;
        }

        pagination["totalPage"] = totalPage;

        pagination["pageNo"] = index;

        const pageNumbers = [];
        for (let i = 1; i <= totalPage; i++) {
            if (i - index <= 5 || i === totalPage - 1) pageNumbers.push(i);
        }
        pagination["paginationItems"] = pageNumbers;

        setPagination(pagination);
        console.log("paginationItems");
        console.log(pagination["paginationItems"]);

        getData(index, search);
    };

    //! get orders data
    const getData = async (pageNo = 1, textSearch, serviceId) => {
        //   console.log("stttatstter")
        showLoader();
        const formdata = new FormData();
        formdata.append("page", pageNo);
        formdata.append("textSearch", textSearch);
        formdata.append("serviceId", serviceId);
        try {
            const response = await fetch(baseurl + "dashboard/get-orders", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },

                body: formdata,
            });
            const json = await response.json();
            hideLoader();
            source = json;
            count = json.totalPages;
            console.log(json);
            setData(json);
            return json;
        } catch (error) {
            hideLoader();
            console.error(error + "kjjjjjjj");
        }
    };
    // ! update status
    const updateStatus = async (orderId, status) => {
        showLoader();
        try {
            var formdata = new FormData();
            formdata.append("orderId", orderId);
            formdata.append("status", status);

            const response = await fetch(baseurl + "dashboard/update-status-order", {
                body: formdata,
                method: "POST",

                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            const json = await response.json();
            console.log(json);

            hideLoader();
            // setData(json);
            return json;
        }

        catch (error) {
            hideLoader();
            console.error(error + "errrrrrrrr");
        }
    };
    const initData = async () => {
        setServices(await getServices());
        getData(1, search, 0);
        configurePagination(count, 1);
    };

    useEffect(() => {


        if (!data) {
            initData();
        }

    }, []);



    return (
        <div>
            {loader}

            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">الطلبات</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="javascript:;">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                الطلبات
                            </li>
                        </ol>
                    </nav>
                </div>
                {/* <div className="ms-auto">
      <Link to={"/admin/market/add"}>

        <div className="btn-group">
          <a  className="btn btn-success">إضافة +</a>
        </div>
        </Link>
      </div> */}
            </div>
            {/*end breadcrumb*/}
            <hr />
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div
                            id="example2_wrapper"
                            className="dataTables_wrapper dt-bootstrap5"
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <p></p>
                                    <div className="row justify-content-start">
                                        <div className="col-12 col-md-4">
                                            <div className="card-body row no-gutters align-items-start">
                                                {/*end of col*/}
                                                <div className="col">
                                                    <input
                                                        autoComplete="off"
                                                        onChange={(e) => (search = e.target.value)}
                                                        name="SearchData"
                                                        className="form-control form-control form-control-borderless"
                                                        type="search"
                                                        placeholder=" بحث في القائمة برقم الطلب"
                                                    />
                                                </div>
                                                {/*end of col*/}
                                                <div className="col-auto">
                                                    <button
                                                        onClick={(e) => getData(1, search, serviceId)}
                                                        className="btn btn btn-primary"
                                                        type="submit"
                                                    >
                                                        بحث
                                                    </button>
                                                </div>
                                                {/*end of col*/}
                                            </div>
                                        </div>
                                        {/*end of col*/}



                                    </div>

                                    <div className="col-4">
                                        <label className="form-label">اختر نوع الطلب  </label>
                                        {services && (
                                            <select

                                                required
                                                // name="ServiceId"
                                                onChange={async (value) => {



                                                    await getData(1, search, value.target.value)

                                                    setServiceId(value.target.value)

                                                }}
                                                id="school-id"
                                                className="form-select"
                                            >
                                                <option value={""}>اختر</option>
                                                {services.map((row) => (
                                                    <option key={row.id} value={row.id}>
                                                        {row.nameAr}
                                                    </option>
                                                ))}
                                            </select>
                                        )}

                                        <span
                                            asp-validation-for="FieldId"
                                            className="text-danger"
                                        />
                                    </div>
                                    <p></p>
                                    <table
                                        id="DataTable"
                                        className="table table-striped table-bordered dataTable"
                                        role="grid"
                                        aria-describedby="example2_info"
                                    >
                                        <thead>
                                            <tr role="row">
                                                <th
                                                    className="sorting_asc text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-sort="ascending"
                                                    aria-label="Name: activate to sort column descending"
                                                    style={{ width: "25.828px" }}
                                                >
                                                    <span className="mx-3">#</span>
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "50.891px" }}
                                                >
                                                    اسم العميل
                                                </th>
                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "50.891px" }}
                                                >
                                                    رقم الهاتف
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "60.891px" }}
                                                >
                                                    التاريخ
                                                </th>


                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "50.891px" }}
                                                >
                                                    حالة الطلب
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "50.891px" }}
                                                >
                                                    حالة الوكيل
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "60.891px" }}
                                                >
                                                    تعديل
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Office: activate to sort column ascending"
                                                    style={{ width: "50.312px" }}
                                                >
                                                    فعل
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {data &&
                                                data.items.map((item, index) => (
                                                    <tr role="row" className="odd">
                                                        {/* <span className="mx-3">{rowIndex(index)}</span> */}
                                                        <td className="text-center align-middle">
                                                            {item.order.id}
                                                        </td>
                                                   
                                                        <td className="text-center align-middle">
                                                            {item.user&& item.user.firstName + " ," + item.user.lastName}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.user&& item.user.phoneNumber}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {formatDateToText(item.order.createdAt)}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {listStatusOrder[item.order.status].name}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.order.agentId ? "تم ارساله للوكيل" : "غير مرسل"}
                                                        </td>



                                                        <td className="text-center align-middle m-1 ">

                                                            <select

                                                                required
                                                                // name="ServiceId"
                                                                onChange={async (value) => {
                                                                    //! update order status
                                                                    console.log(value.target.value + "wefwwvefd");
                                                                    item.status = value.target.value
                                                                    await updateStatus(item.order.id, value.target.value);

                                                                }}
                                                                id="status-id"
                                                                className="form-select"
                                                            >
                                                                <option value={""}>تعديل حالة الطلب </option>
                                                                {item.order.typeStatus === 0 ? listStatusOrder.map((row) => (
                                                                    <option key={row.id} value={row.id}>
                                                                        {row.name}
                                                                    </option>
                                                                )) : listStatusOrderFurniture.map((row) => (
                                                                    <option key={row.id} value={row.id}>
                                                                        {row.name}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                        </td>
                                                        <td className="text-center align-middle">

                                                            <DeleteButton icon="far fa-trash-alt" onClick={() => {

                                                                // print 
                                                                console.log("delete");

                                                                navigate(deleteOrder, { state: { row: item } });
                                                            }} />

                                                            <ViewButton icon="fas fa-eye" onClick={() => {

                                                                // nave to order details
                                                                console.log("view");
                                                                navigate(orderDetails, { state: { row: item.order } });

                                                            }} />

                                                            {/* <UpdateButton icon="fas fa-edit" onClick={() => {
                                                                // print 
                                                                console.log("update");
                                                            }} /> */}

                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    <br />
                                    {data && (
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                                <li
                                                    onClick={(e) =>
                                                        configurePagination(
                                                            pagination["totalPage"],
                                                            pagination["pageNo"] - 1
                                                        )
                                                    }
                                                    class="page-item"
                                                >
                                                    <a class="page-link" href="#" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                        <span class="sr-only">Previous</span>
                                                    </a>
                                                </li>

                                                {pagination["paginationItems"]?.map(
                                                    (_, i) =>
                                                        pagination["pageNo"] &&
                                                        (pagination["pageNo"] - i < 5 &&
                                                            i - pagination["pageNo"] < 5 ? (
                                                            <li
                                                                onClick={(e) =>
                                                                    configurePagination(
                                                                        pagination["totalPage"],
                                                                        i + 1
                                                                    )
                                                                }
                                                                class={
                                                                    i + 1 != pagination["pageNo"]
                                                                        ? "page-item"
                                                                        : "page-item active"
                                                                }
                                                            >
                                                                <a class="page-link" href="#">
                                                                    {i + 1}
                                                                </a>
                                                            </li>
                                                        ) : (
                                                            <></>
                                                        ))
                                                )}

                                                <li
                                                    onClick={(e) =>
                                                        configurePagination(
                                                            pagination["totalPage"],
                                                            pagination["pageNo"] + 1
                                                        )
                                                    }
                                                    class="page-item"
                                                >
                                                    <a class="page-link" href="#" aria-label="Next">
                                                        <span aria-hidden="true">&raquo;</span>
                                                        <span class="sr-only">Next</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
