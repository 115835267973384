import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import {
  baseurl,
  OnInput,
  onInputInvalid,
  successNotify,
} from "../../constants";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import moment from "moment/moment";
export default function EditMarket() {
  const location = useLocation();
  const row = location.state.row;

  var [image, setImage] = useState([]);

  const handleNewImage = (event) => {
    image = event.target.files;
  };
  const arr = [];




  const [file, setFile] = useState(null);
  const [images, setImages] = useState();

  const [categoryId, setCategoryId] = useState();
  const [services, setServices] = useState();
  const [serviceId, setServiceId] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const [files, setFiles] = useState(null);
  const [nameAr, setNameAr] = useState();
  const [nameEn, setNameEn] = useState();
  const [aboutAr, setAboutAr] = useState();

  const [link, setLink] = useState();
  const [phone, setPhone] = useState();

  const [linkTiktok, setLinkTiktok] = useState();
  const [linkConnectUs, setLinkConnectUs] = useState();

  const [aboutEng, setAboutEng] = useState();
  const [order, setOrder] = useState("");
  const [openTime, setOpenTime] = useState('');
  const [closeTime, setCloseTime] = useState('');
  const [addressName, setAddressName] = useState('');
  const [email, setEmail] = useState('');



  const getServices = async () => {
    try {
      const response = await fetch(baseurl + "servies/get-Services", {
        method: "GET",
      });
      const json = await response.json();
      console.log(json);
      setServices(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  const getCategories = async (serviceId) => {
    try {
      const response = await fetch(
        baseurl + "category/get-categories-byservicId?serviceId=" + serviceId,
        {
          method: "GET",
        }
      );
      const json = await response.json();
      console.log(json);
      setCategories(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };


  const navigateTo = useNavigate();

  const postForm = async (event) => {
    // let uploadedImage = await uploadImages(image);
    console.log(arr);
    showLoader();
    event.preventDefault();
    try {
      // * form data
      const formdata = new FormData();
      if (files === null) {
        console.log("not");
        formdata.append("images", row.market.images);
      } else {
        await uploadImages();
        formdata.append("images", arr.join("#"));
      }


      formdata.append(
        "logoImage",
        file === null ? row.logoImage : image
      );
      formdata.append("Status", "0");

      formdata.append("id", row.id);
      formdata.append("aboutAr", aboutAr);
      formdata.append("aboutEng", aboutEng);
      formdata.append("nameAr", nameAr);
      formdata.append("nameEng", nameEn);
      formdata.append("LinkInstgram", link);
      formdata.append("LinkTiktok", linkTiktok);
      formdata.append("LinkConnectUs", linkConnectUs);
      formdata.append("order", order);
      formdata.append("openTime", openTime);
      formdata.append("openClose", closeTime);
      formdata.append("CategoryId", categoryId ?? "0");
      formdata.append("Email", email);
      formdata.append("Phone", phone);
      formdata.append("addressName", addressName);


      formdata.append("ServiceId", serviceId);






      const response = await fetch(baseurl + "Market/update-Market", {
        method: "POST",
        redirect: "follow",
        body: formdata,
      });
      const json = await response.json();
      hideLoader();
      successNotify();
      // console.log(json + "fdfndlsfjdsifsldflds");
      navigateTo("/admin/Markets");
    } catch (e) {
      console.log(e + "fgvfdgd");
    }
  };

  const initData = async () => {
    arr.slice();
    await getServices();
    if (row != null) {

      setNameAr(row.nameAr);

      setNameEn(row.nameEng);
      setAboutAr(row.aboutAr);
      setAboutEng(row.aboutEng);
      setPhone(row.phone);
      setLink(row.linkInstgram);
      setImages(row.images);
      setLinkTiktok(row.linkTiktok);
      setLinkConnectUs(row.linkConnectUs);
      setServiceId(row.serviceId);
      setCategoryId(row.categoryId);
      setOrder(row.order);

      setAddressName(row.addressName);
      setEmail(row.email);
      setOpenTime(row.openTime);
      setCloseTime(row.openClose);
      if (row.categoryId !== 0) {
        await getCategories(row.market.serviceId);
      }
    }


  };

  // ** upload image
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await axios
        .post(baseurl + "image/upload/image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((e) => {
          setImage(e.data);

          console.log(e.data);
        });
    } catch (e) {
      console.log(e);
    }
  };



  useEffect(() => {

    initData();
  }, []);


  //! date open time
  const handleOpenTime = (event) => {
    setOpenTime(event.target.value);
  };

  //! date close time
  const handleCloseTime = (event) => {
    setCloseTime(event.target.value);
  };

  const uploadImages = async (image) => {
    arr.slice();
    for (const element of files) {
      try {
        const formData = new FormData();
        formData.append("file", element);

        const resp = await axios
          .post(baseurl + "image/upload/image", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((e) => {
            arr.push(e.data);
            setImages(arr.join("#"));
          });
      } catch (e) {
        console.log(e);
      }
    }
    console.log(arr.join("#"));
    return arr.join("#");
  };

  const handleRemove = (id) => {
    const fileItem = files.filter((person) => person !== id);

    setFiles(fileItem);
  };

  const handleSliderImages = (e) => {
    if (e.target.files) {
      setFiles([...e.target.files]);
    }
    console.log("Update slider images", files);
  };

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">المتاجر</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                إضافة متجر
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/*end breadcrumb*/}
      <hr />
      <div className="col-xl-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">معلومات المتجر</h6>
              <hr />
              <form onSubmit={postForm} encType="multipart/form-data">
                <div
                  asp-validation-summary="ModelOnly"
                  className="text-danger"
                />
                <div className="row g-3">  <div className="col-4">
                  <label className="form-label">اختر الفئة </label>
                  {services && (
                    <select
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      // name="ServiceId"
                      onChange={async (value) => {
                        setServiceId(value.target.value);
                        // console.log(value.target.value);
                        await getCategories(value.target.value);
                      }}
                      id="school-id"
                      className="form-select"
                    >
                      <option value={""}>اختر</option>
                      {services.map((row) => (
                        <option key={row.id} value={row.id}>
                          {row.nameAr}
                        </option>
                      ))}
                    </select>
                  )}

                  <span
                    asp-validation-for="FieldId"
                    className="text-danger"
                  />
                </div>
                  {/* categories  */}
                  <div className="col-4">
                    <label className="form-label">اختر القسم </label>
                    {categories.categories && (
                      <select
                        // name="categoryId"
                        onChange={(value) => {
                          setCategoryId(value.target.value);
                        }}
                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        // required
                        id="categoryId"
                        className="form-select"
                      >

                        {categories.categories
                          .map((row) => (
                            <option key={row.id} value={row.id}>
                              {row.nameAr}
                            </option>
                          ))}
                      </select>
                    )}{" "}
                    <span
                      asp-validation-for="FieldId"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-6">
                    <label className="form-label">الإسم باللغة العربية </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      onChange={(value) => {
                        setNameAr(value.target.value);
                      }}
                      defaultValue={nameAr}
                      required
                      autoComplete="off"
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="Name" className="text-danger" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      الإسم باللغة الإنجليزية{" "}
                    </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      onChange={(value) => {
                        setNameEn(value.target.value);
                      }}
                      defaultValue={nameEn}
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">وصف عن المتجر </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      onChange={(value) => {
                        setAboutAr(value.target.value);
                      }}
                      defaultValue={aboutAr}
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="Info" className="text-danger" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">وصف عن المتجر بالإنجليزية </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      onChange={(value) => {
                        setAboutEng(value.target.value);
                      }}
                      type="text"
                      defaultValue={aboutEng}
                      className="form-control"
                    />
                    <span asp-validation-for="Info" className="text-danger" />
                  </div>


                  <div className="col-6">
                    <label className="form-label"> البريد الإلكتروني </label>
                    <input
                      required
                      autoComplete="off"
                      name="Email"
                      type="text"
                      defaultValue={email}
                      className="form-control"
                      onChange={(value) => {
                        setEmail(value.target.value);
                      }}
                    />
                  </div>
                  {/*  // ! linkes start  ==================================  */}

                  {/* * Connect us   */}
                  <div className="col-6">
                    <label className="form-label">رابط التواصل </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="LinkConnectUs"
                      type="text"
                      className="form-control"
                      defaultValue={linkConnectUs}
                      onChange={(value) => {
                        setLinkConnectUs(value.target.value);
                      }}
                    />
                  </div>

                  {/* * whats app   */}
                  <div className="col-6">
                    <label className="form-label">رقم الواتساب  </label>
                    <input
                      required
                      autoComplete="off"
                      name="Phone"
                      type="text"
                      defaultValue={phone}
                      onChange={(value) => {
                        setPhone(value.target.value);
                      }}
                      // value={phone}
                      className="form-control"
                    />
                  </div>

                  {/* * instagram   */}

                  <div className="col-6">
                    <label className="form-label">رابط الانستقرام  </label>
                    <input
                      required
                      autoComplete="off"
                      defaultValue={link}
                      name="LinkInstgram"
                      type="text"
                      className="form-control"
                      onChange={(value) => {
                        setLink(value.target.value);
                      }}
                    />
                  </div>

                  {/* * tick tok  */}
                  <div className="col-6">
                    <label className="form-label">رابط التيك توك </label>
                    <input

                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="LinkTiktok"
                      type="text"
                      className="form-control"
                      defaultValue={linkTiktok}
                      onChange={(value) => {
                        setLinkTiktok(value.target.value);
                      }}
                    />
                  </div>

                  {/*  // ! linkes end  ==================================  */}


                  <div className="col-6">
                    <label className="form-label"> العنوان  </label>
                    <input
                      required
                      autoComplete="off"
                      name="AddressName"
                      type="text"
                      defaultValue={addressName}
                      className="form-control"
                      onChange={(value) => {
                        setAddressName(value.target.value);
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <label className="form-label">الترتيب </label>
                    <input
                      autoComplete="off"
                      defaultValue={order}
                      name="Order"
                      type="Number"
                      className="form-control"
                      onChange={(value) => {
                        setOrder(value.target.value);
                      }}
                    />
                  </div>




                  {/* open time ans close */}
                  <div className="col-6">
                    <label className="form-label">وقت فتح المتجر</label>
                    <input
                      required
                      type="time"
                      date={openTime}
                      name="OpenTime"
                      id="dateInput"
                      value={openTime}
                      onChange={handleOpenTime}
                    />

                  </div>

                  <div className="col-6">
                    <label className="form-label">وقت اغلاق المتجر</label>
                    <input
                      required
                      type="time"
                      defaultValue={moment(closeTime).format("HH:mm")}
                      name="OpenClose"
                      id="dateInput"
                      value={closeTime}
                      onChange={handleCloseTime}
                    />

                  </div>

                  <div className="col-6">
                    <label className="form-label">شعار المتجر </label>
                    <div className="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        onChange={(e) => {
                          setFile(URL.createObjectURL(e.target.files[0]));
                          uploadImage(e.target.files[0]);
                        }}
                      />

                      <img
                        src={
                          file === null
                            ? baseurl + "images/" + row.logoImage
                            : file
                        }
                        width={64}
                        height={64}
                        alt=""
                      />
                    </div>
                  </div>

                  {/*  // ! images start  ==================================  */}
                  <div className="col-12">
                    <label htmlFor="ImageFile" className="form-label">
                      صور للمتجر{" "}
                      <span className="text-success">
                        حجم الصورة ( 1200x600 )
                      </span>
                    </label>
                    <input
                   
                      multiple="multiple"
                      // onInvalid={onInputInvalid}
                      onInput={OnInput}
                      onChange={handleSliderImages}
                      defaultValue={images}
                      // name="images"
                      className="form-control form-control-lg"
                      id="formFileLg"
                      type="file"
                    />
                    <span
                      asp-validation-for="ImageFile"
                      className="text-danger"
                    />
                  </div>
                  <div>
                    {files != null
                      ? files.map((item, i) => (
                        <div key={i} className="col">
                          <div className="image-style">
                            <button
                              type="button"
                              onClick={() => handleRemove(item)}
                              class="btn-close btn-close"
                              aria-label="Close"
                            ></button>
                            <img src={URL.createObjectURL(item)} alt="" />
                          </div>
                        </div>
                      ))
                      : row.images!=null && row.images.split("#").map((item, i) => (
                        <div key={i} className="col">
                          <div className="image-style">
                            <img src={baseurl + "images/" + item} alt="" />
                          </div>
                        </div>
                      ))}
                  </div>


                  <div className="col-12">
                    <div className="d-grid">
                      <input

                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        required
                        type="submit"
                        value="حفظ"
                        className="btn btn-primary"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
