import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import ViewButton from "../../Components/Buttons/ViewButton";
import UpdateButton from "../../Components/Buttons/UpdateButton";

var count;
var search = "";
var source = [];

export default function Agents() {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [pagination, setPagination] = useState({});
    const [loader, showLoader, hideLoader] = useLoader();

    const configurePagination = (recordCount, index) => {
        if (index < 1 || index > count) return;

        let pagination = {};
        let pageSize = 1;
        const total = count / pageSize;
        var totalPage = parseInt(total);
        if (total > totalPage) {
            totalPage = totalPage + 1;
        }

        pagination["totalPage"] = totalPage;
        pagination["pageNo"] = index;

        const pageNumbers = [];
        for (let i = 1; i <= totalPage; i++) {
            if (i - index <= 5 || i === totalPage - 1) pageNumbers.push(i);
        }
        pagination["paginationItems"] = pageNumbers;

        setPagination(pagination);
        getData(index, search);
    };

    const getData = async (pageNo = 1, textSearch) => {
        showLoader();
        const formdata = new FormData();
        formdata.append("page", pageNo);
        formdata.append("textSearch", textSearch);
        
        try {
            const response = await fetch(baseurl + "dashboard/get-agents-page", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formdata,
            });
            const json = await response.json();
            hideLoader();
            source = json;
            count = json.totalPages;
            setData(json);
            return json;
        } catch (error) {
            hideLoader();
            console.error(error);
        }
    };

    const initData = async () => {
        getData(1, search);
        configurePagination(count, 1);
    };

    useEffect(() => {
        if (!data) {
            initData();
        }
    }, []);

    return (
        <div>
            {loader}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">الوكلاء</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="javascript:;">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                الوكلاء
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <Link to="/admin/user/roles">
                        <div className="btn-group">
                            <a className="btn btn-success">إضافة وكيل جديد +</a>
                        </div>
                    </Link>
                </div>
            </div>
            <hr />
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row justify-content-start">
                                        <div className="col-12 col-md-4">
                                            <div className="card-body row no-gutters align-items-start">
                                                <div className="col">
                                                    <input
                                                        autoComplete="off"
                                                        onChange={(e) => (search = e.target.value)}
                                                        name="SearchData"
                                                        className="form-control form-control form-control-borderless"
                                                        type="search"
                                                        placeholder="بحث في القائمة"
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        onClick={(e) => getData(1, search)}
                                                        className="btn btn btn-primary"
                                                        type="submit"
                                                    >
                                                        بحث
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <table
                                        id="DataTable"
                                        className="table table-striped table-bordered dataTable"
                                        role="grid"
                                        aria-describedby="example2_info"
                                    >
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting_asc text-center align-middle" tabIndex={1}>
                                                    <span className="mx-1">#</span>
                                                </th>
                                                <th className="sorting text-center align-middle">الاسم</th>
                                                <th className="sorting text-center align-middle">رقم الهاتف</th>
                                                
                                                <th className="sorting text-center align-middle">الحالة</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.items.map((item, index) => (
                                                    <tr role="row" className="odd" key={item.id}>
                                                        <td className="text-center align-middle">{index + 1}</td>
                                                        <td className="text-center align-middle">
                                                            {item.firstName} {item.lastName}
                                                        </td>
                                                        <td className="text-center align-middle">{item.phoneNumber}</td>
                                                        
                                                        <td className="text-center align-middle">
                                                            <span className={`badge ${item.status === 0 ? 'bg-success' : 'bg-danger'}`}>
                                                                {item.status === 0 ? 'نشط' : 'غير نشط'}
                                                            </span>
                                                        </td>
                                                        
                                                        
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    {data && (
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li
                                                    onClick={() => configurePagination(pagination["totalPage"], pagination["pageNo"] - 1)}
                                                    className="page-item"
                                                >
                                                    <a className="page-link" href="#" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                </li>
                                                {pagination["paginationItems"]?.map((_, i) =>
                                                    pagination["pageNo"] &&
                                                    (pagination["pageNo"] - i < 5 && i - pagination["pageNo"] < 5 ? (
                                                        <li
                                                            key={i}
                                                            onClick={() => configurePagination(pagination["totalPage"], i + 1)}
                                                            className={i + 1 !== pagination["pageNo"] ? "page-item" : "page-item active"}
                                                        >
                                                            <a className="page-link" href="#">
                                                                {i + 1}
                                                            </a>
                                                        </li>
                                                    ) : null)
                                                )}
                                                <li
                                                    onClick={() => configurePagination(pagination["totalPage"], pagination["pageNo"] + 1)}
                                                    className="page-item"
                                                >
                                                    <a className="page-link" href="#" aria-label="Next">
                                                        <span aria-hidden="true">&raquo;</span>
                                                        <span className="sr-only">Next</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 