import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { format, parseISO, isValid } from "date-fns";
import axios from "axios";

export default function CreateCarRental() {
    const navigate = useNavigate();
    const location = useLocation();
    const isEditMode = location.state?.row !== undefined;
    const editData = location.state?.row;
    
    const [loader, showLoader, hideLoader] = useLoader();
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        serviceId: 10,
        nameAr: "",
        nameEng: "",
        descAr: "",
        descEng: "",
        logo: "",
        countryId: "",
        price: 0,
        disntanc: 0,
        startDay: null,
        endDay: null,
        createAte: new Date()
    });
    const [imagePreview, setImagePreview] = useState(null);

    // Initialize form data if in edit mode
    useEffect(() => {
        if (isEditMode && editData) {
            const startDate = editData.startDay ? parseISO(editData.startDay) : null;
            const endDate = editData.endDay ? parseISO(editData.endDay) : null;
            
            setFormData({
                ...editData,
                startDay: startDate && isValid(startDate) ? format(startDate, "yyyy-MM-dd") : null,
                endDay: endDate && isValid(endDate) ? format(endDate, "yyyy-MM-dd") : null,
            });
            
            if (editData.logo) {
                setImagePreview(baseurl + "images/" + editData.logo);
            }
        }
    }, [isEditMode, editData]);

    // Fetch countries
    useEffect(() => {
        const getCountries = async () => {
            try {
                const response = await fetch(baseurl + "country/get-countries", {
                    method: "GET"
                });
                const json = await response.json();
                if (json && Array.isArray(json)) {
                    setCountries(json);
                }
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };
        getCountries();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateChange = (field, value) => {
        if (value && isValid(value)) {
            setFormData(prev => ({
                ...prev,
                [field]: format(value, "yyyy-MM-dd")
            }));
        }
    };

    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await axios.post(baseurl + "image/upload/image", formData, {
                headers: {
                    "content-type": "multipart/form-data",
                }
            });

            if (response.data) {
                setFormData(prev => ({
                    ...prev,
                    logo: response.data
                }));
                
                // Set preview
                setImagePreview(baseurl + "images/" + response.data);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        showLoader();

        try {
            const formDataToSend = new FormData();
            
            // Append all form fields
            if (isEditMode) {
                formDataToSend.append("id", editData.id);
            }
            formDataToSend.append("serviceId", formData.serviceId);
            formDataToSend.append("nameAr", formData.nameAr);
            formDataToSend.append("nameEng", formData.nameEng);
            formDataToSend.append("descAr", formData.descAr);
            formDataToSend.append("descEng", formData.descEng);
            formDataToSend.append("logo", formData.logo);
            formDataToSend.append("countryId", formData.countryId);
            formDataToSend.append("price", formData.price);
            formDataToSend.append("disntanc", formData.disntanc);
            formDataToSend.append("startDay", formData.startDay);
            formDataToSend.append("endDay", formData.endDay);
            formDataToSend.append("currency", formData.currency);

            const endpoint = isEditMode ? "car-rental/update" : "car-rental/add";
            const method = isEditMode ? "PUT" : "POST";

            const response = await fetch(baseurl + endpoint, {
                method: method,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formDataToSend
            });

            if (response.ok) {
                navigate("/admin/carRentals");
            } else {
                console.error(isEditMode ? "Error updating car rental" : "Error creating car rental");
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            hideLoader();
        }
    };

    return (
        <div>
            {loader}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">
                    {isEditMode ? "تعديل سيارة" : "إضافة سيارة جديدة"}
                </div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="/#"><i className="bx bx-home-alt" /></a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {isEditMode ? "تعديل سيارة" : "إضافة سيارة"}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <hr />

            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <TextField
                                    fullWidth
                                    label="الاسم بالعربية"
                                    name="nameAr"
                                    value={formData.nameAr}
                                    onChange={handleInputChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="الاسم بالإنجليزية"
                                    name="nameEng"
                                    value={formData.nameEng}
                                    onChange={handleInputChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="الوصف بالعربية"
                                    name="descAr"
                                    value={formData.descAr}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="الوصف بالإنجليزية"
                                    name="descEng"
                                    value={formData.descEng}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                    sx={{ mb: 2 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    fullWidth
                                    label="السعر"
                                    name="price"
                                    type="number"
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label=" العملة ( مثال : kW , EGP , USD , SAR )"
                                    name="currency"
                                    value={formData.currency}
                                    onChange={handleInputChange}
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel>الدولة</InputLabel>
                                    <Select
                                        name="countryId"
                                        value={formData.countryId}
                                        label="الدولة"
                                        onChange={handleInputChange}
                                        required
                                    >
                                        {countries.map((country) => (
                                            <MenuItem key={country.id} value={country.id}>
                                                {country.nameAr}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <div className="mb-3">
                                    <label className="form-label">الصورة</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => uploadImage(e.target.files[0])}
                                    />
                                    {imagePreview && (
                                        <div className="mt-2">
                                            <img
                                                src={imagePreview}
                                                alt="Preview"
                                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                            />
                                        </div>
                                    )}
                                </div>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <div className="mb-3">
                                        <DatePicker
                                            label="يوم البداية"
                                            value={formData.startDay ? new Date(formData.startDay) : null}
                                            onChange={(newValue) => handleDateChange('startDay', newValue)}
                                            slotProps={{ 
                                                textField: { 
                                                    fullWidth: true,
                                                    sx: { mb: 2 }
                                                } 
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <DatePicker
                                            label="يوم النهاية"
                                            value={formData.endDay ? new Date(formData.endDay) : null}
                                            onChange={(newValue) => handleDateChange('endDay', newValue)}
                                            slotProps={{ 
                                                textField: { 
                                                    fullWidth: true,
                                                    sx: { mb: 2 }
                                                } 
                                            }}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary px-5">
                                    حفظ
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary px-5 ms-2"
                                    onClick={() => navigate("/admin/carRentals")}
                                >
                                    إلغاء
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}