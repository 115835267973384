import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  baseurl,
} from "../../constants";
import { Card, CardContent, Typography, Grid, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from "axios";

export default function UserDetail() {
  const location = useLocation();
  const row = location.state.row;
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleAr, setTitleAr] = useState('');
  const [titleEng, setTitleEng] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [descriptionEng, setDescriptionEng] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const response = await fetch(baseurl + "attachments/get-user-attachments?userId=" + row.id, {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        
        });
        const data = await response.json();
        setAttachments(data.data);
      } catch (error) {
        console.error("Error fetching attachments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAttachments();
  }, [row.id]);

  const getFileIcon = (fileType) => {
    if (fileType.startsWith('image/')) return <ImageIcon sx={{ fontSize: 40 }} />;
    if (fileType === 'application/pdf') return <PictureAsPdfIcon sx={{ fontSize: 40 }} />;
    if (fileType.startsWith('text/') || fileType.includes('document')) return <DescriptionIcon sx={{ fontSize: 40 }} />;
    return <InsertDriveFileIcon sx={{ fontSize: 40 }} />;
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };



  const handleSendAlert = async () => {
    try {
      const formData = new FormData();
      formData.append('titleAr', titleAr);
      formData.append('titleEng', titleEng);
      formData.append('descriptionAr', descriptionAr);
      formData.append('descriptionEng', descriptionEng);
      if (imageFile) formData.append('image', imageFile);
      formData.append('userId', row.id);

      const response = await fetch(baseurl + "alerts/send-Alert-with-token", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: formData
      });

      if (response.ok) {
        setOpenDialog(false);
        // Reset form
        setTitleAr('');
        setTitleEng('');
        setDescriptionAr('');
        setDescriptionEng('');
        setImageFile(null);
        toast.success("تم إرسال التنبيه بنجاح");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

   // upload image
   const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(baseurl + "image/upload/image", formData, {
          headers: {
              "content-type": "multipart/form-data",
          }
      });

      if (response.data) {
          setImageFile(response.data);
          
          // Set preview
            setImagePreview(baseurl + "images/" + response.data);
      }
  } catch (error) {
      console.error("Error uploading image:", error);
  }
};

  return (
    <>
      <ToastContainer />
      {/* <h1>{row.title}</h1> */}
      <div className="">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">المستخدمين</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  تفاصيل
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <hr />
        <div className="col-xl-12 mx-auto">
          <div className="card">
            <div className="card-body">
              <div className="border p-3 rounded">
                <h6 className="mb-0 text-uppercase">معلومات المستخدم</h6>
                <hr />
                <div
                  asp-validation-summary="ModelOnly"
                  className="text-danger"
                />
                {/* * name , address*/}
                <div className="row g-3">
                  <div className="col-6">
                    <div className="col-12">
                      <label className="h5 form-label">الإسم</label>
                      <input
                        disabled
                        autoComplete="off"
                        value={row.firstName + " " + row.lastName ?? "غير مسجل"}
                        type="text"
                        className="form-control"
                      />
                      <span asp-validation-for="Name" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="col-12">
                      <label className="h5 form-label">رقم الهاتف</label>
                      <input
                        disabled
                        autoComplete="off"
                        value={row.phoneNumber ?row.phoneNumber: "غير معروف"}
                        type="text"
                        className="form-control"
                      />
                      <span asp-validation-for="Name" className="text-danger" />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {/* * admin , details*/}
                <div className="row g-3">
                  <div className="col-6">
                    <div className="col-12">
                      <label className="h5 form-label">الايميل</label>
                      <input
                        disabled
                        autoComplete="off"
                        value={row.userName ?? "غير مسجل"}
                        type="text"
                        className="form-control"
                      />
                      <span asp-validation-for="Name" className="text-danger" />
                    </div>
                  </div>

                  {/* phone */}

                  <div className="col-6">
                    <div className="col-12">
                      <label className="h5 form-label">العنوان  </label>
                      <input
                        disabled
                        autoComplete="off"
                        value={row.address}
                        type="text"
                        className="form-control"
                      />
                      <span asp-validation-for="Name" className="text-danger" />
                    </div>
                  </div>

                 

                  <div className="col-12 text-center">
                    <label htmlFor="ImageFile" className="h5 form-label">
                      {" "}
                      صورة الملف
                    </label>
                    <div >
                      <a href={baseurl + "/images/" + row.profileImage}>
                        <img
                          style={{ width: "10vw", height: "10vw" }}
                          src={baseurl + "images/" + row.profileImage}
                          className="rounded float-center"
                        />
                      </a>
                    </div>
                  </div>


                  <div className="col-12 mt-4">
                    <Button 
                      variant="contained" 
                      color="primary"
                      onClick={() => setOpenDialog(true)}
                      className="w-100"
                    >
                      إرسال إشعار
                    </Button>

                    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                      <DialogTitle>إرسال إشعار جديد</DialogTitle>
                      <DialogContent>
                        <div className="row g-3 mt-1">
                          <div className="col-md-6">
                            <TextField
                              label="عنوان الإشعار (عربي)"
                              fullWidth
                              value={titleAr}
                              onChange={(e) => setTitleAr(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              label="عنوان الإشعار (English)"
                              fullWidth
                              value={titleEng} 
                              onChange={(e) => setTitleEng(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              label="الرسالة (عربي)"
                              fullWidth
                              multiline
                              rows={4}
                              value={descriptionAr}
                              onChange={(e) => setDescriptionAr(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              label="الرسالة (English)"
                              fullWidth
                              multiline
                              rows={4}
                              value={descriptionEng}
                              onChange={(e) => setDescriptionEng(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => uploadImage(e.target.files[0])}
                              className="form-control"
                            />
                            {imagePreview && (
                              <div className="mt-2">
                                <img
                                  src={imagePreview}
                                  alt="Preview"
                                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>إلغاء</Button>
                        <Button onClick={handleSendAlert} variant="contained" color="primary">
                          إرسال
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        {/* Attachments Section */}
        <div className="col-xl-12 mx-auto mt-4">
          <div className="card">
            <div className="card-body">
              <div className="border p-3 rounded">
                <h6 className="mb-0 text-uppercase">ملفات المستخدم</h6>
                <hr />
                {loading ? (
                  <Typography>جاري تحميل الملفات...</Typography>
                ) : !Array.isArray(attachments) || attachments.length === 0 ? (
                  <Typography>لا توجد ملفات مرفقة</Typography>
                ) : (
                  <Grid container spacing={2}>
                    {attachments.map((attachment, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card 
                          sx={{ 
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: 6
                            }
                          }}
                          onClick={() => window.open(baseurl + attachment.filePath, '_blank')}
                        >
                          <CardContent sx={{ textAlign: 'center' }}>
                            <Box sx={{ color: 'primary.main', mb: 1 }}>
                              {getFileIcon(attachment.fileName.split('.').pop())}
                            </Box>
                            <Typography variant="subtitle1" noWrap>
                              {attachment.fileName || 'Unnamed File'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {new Date(attachment.createAte).toLocaleDateString()}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <FieldMarkets id={row.id}/> */}
    </>
  );
}
