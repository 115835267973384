import { useEffect, useState } from "react";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Box, Paper, TextField } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

export default function UserRoles() {
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const navigate = useNavigate();

  const searchUser = async () => {
    if (!searchQuery.trim()) return;
    
    showLoader();
    try {
      const formdata = new FormData();
      formdata.append("page", 1);
      formdata.append("textSearch", searchQuery);

      const response = await fetch(baseurl + "dashboard/get-users", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      const json = await response.json();
      
      if (json.items && json.items.length > 0) {
        setUser(json.items[0]);
      } else {
        setUser(null);
      }
      
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error searching user:", error);
    }
  };

  const changeUserRole = async (userId, currentRole) => {
    showLoader();
    try {
      var formdata = new FormData();
      formdata.append("userId", userId);
      formdata.append("role", currentRole === 'user' ? 'agent' : 'user');

      const response = await fetch(baseurl + "dashboard/change-user-role", {
        body: formdata,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      
      setSuccessMessage(currentRole === 'user' ? 'تم تحويل المستخدم إلى وكيل بنجاح' : 'تم تحويل الوكيل إلى مستخدم بنجاح');
      setSuccessDialog(true);
      
      // Navigate back to users page after 2 seconds
      setTimeout(() => {
        navigate("/admin/users");
      }, 2000);
      
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error changing user role:", error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    searchUser();
  };

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">إدارة أدوار المستخدمين</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                إدارة الأدوار
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <hr />

      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSearch} className="mb-4">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ابحث عن المستخدم بالبريد الإلكتروني أو رقم الهاتف"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button className="btn btn-primary" type="submit">
                    بحث
                  </button>
                </div>
              </div>
            </div>
          </form>

          {user ? (
            <div className="row justify-content-center">
              <div className="col-md-6">
                <Paper elevation={3} className="p-4">
                  <div className="d-flex align-items-center mb-4">
                    <img
                      src={baseurl + "/images/" + user.profileImage}
                      className="rounded-circle me-3"
                      width={80}
                      height={80}
                      alt=""
                    />
                    <div>
                      <h5 className="mb-1">{user.firstName + " " + user.lastName}</h5>
                      <p className="mb-1 text-muted">{user.userName}</p>
                      <p className="mb-0 text-muted">{user.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className={`badge ${user.role === 'user' ? 'bg-primary' : 'bg-success'} fs-6`}>
                      {user.role === 'user' ? 'مستخدم' : 'وكيل'}
                    </span>
                    <button
                      onClick={() => changeUserRole(user.id, user.role)}
                      className={`btn ${user.role === 'user' ? 'btn-success' : 'btn-primary'}`}
                    >
                      {user.role === 'user' ? 'تحويل إلى وكيل' : 'تحويل إلى مستخدم'}
                    </button>
                  </div>
                </Paper>
              </div>
            </div>
          ) : searchQuery ? (
            <div className="text-center py-4">
              <Typography variant="h6" color="text.secondary">
                لم يتم العثور على مستخدم بهذا البحث
              </Typography>
            </div>
          ) : null}
        </div>
      </div>

      <Dialog
        open={successDialog}
        onClose={() => setSuccessDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6">نجاح العملية</Typography>
            <IconButton onClick={() => setSuccessDialog(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box className="text-center py-3">
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} className="mb-3" />
            <Typography variant="h6">{successMessage}</Typography>
            <Typography variant="body2" color="text.secondary" className="mt-2">
              سيتم تحويلك إلى صفحة المستخدمين...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
} 