import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../../constants";
import { format, parseISO, isValid } from "date-fns";
import { createCarRental, editeCarRental } from "../Core/Rotes";

export default function CarRentalDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const carRental = location.state?.row;

    const [country, setCountry] = useState(null);

    useEffect(() => {
        const fetchCountry = async () => {
            if (carRental?.countryId) {
                try {
                    const response = await fetch(baseurl + `country/get-country-by-id?id=${carRental.countryId}`, {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    });
                    const data = await response.json();
                    setCountry(data);
                } catch (error) {
                    console.error("Error fetching country:", error);
                }
            }
        };

        fetchCountry();
    }, [carRental]);

    if (!carRental) {
        return <div>No data available</div>;
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'غير محدد';
        try {
            const date = parseISO(dateString);
            if (!isValid(date)) return 'غير محدد';
            return format(date, 'dd/MM/yyyy');
        } catch (error) {
            console.error("Error formatting date:", error);
            return 'غير محدد';
        }
    };

    return (
        <div>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">تفاصيل السيارة</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="/#"><i className="bx bx-home-alt" /></a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                تفاصيل السيارة
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <hr />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h5 className="text-primary">معلومات أساسية</h5>
                                <div className="mt-3">
                                    <p><strong>الاسم بالعربي:</strong> {carRental.nameAr}</p>
                                    <p><strong>الاسم بالإنجليزي:</strong> {carRental.nameEng}</p>
                                    <p><strong>الوصف بالعربي:</strong> {carRental.descAr}</p>
                                    <p><strong>الوصف بالإنجليزي:</strong> {carRental.descEng}</p>
                                </div>
                            </div>

                            <div className="mb-4">
                                <h5 className="text-primary">معلومات السعر والمسافة</h5>
                                <div className="mt-3">
                                    <p><strong>السعر:</strong> {carRental.price}</p>
                                    <p><strong>المسافة:</strong> {carRental.disntanc}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-4">
                                <h5 className="text-primary">معلومات الموقع</h5>
                                <div className="mt-3">
                                    <p><strong>الدولة:</strong> {country ? country.nameAr : 'غير محدد'}</p>
                                </div>
                            </div>

                            <div className="mb-4">
                                <h5 className="text-primary">التواريخ</h5>
                                <div className="mt-3">
                                    <p><strong>تاريخ البداية:</strong> {formatDate(carRental.startDay)}</p>
                                    <p><strong>تاريخ النهاية:</strong> {formatDate(carRental.endDay)}</p>
                                    <p><strong>تاريخ الإنشاء:</strong> {formatDate(carRental.createAte)}</p>
                                </div>
                            </div>

                            <div className="mb-4">
                                <h5 className="text-primary">الصورة</h5>
                                <div className="mt-3">
                                    {carRental.logo && (
                                        <img
                                            src={baseurl + "images/" + carRental.logo}
                                            alt="Car"
                                            style={{
                                                maxWidth: "300px",
                                                maxHeight: "200px",
                                                objectFit: "cover",
                                                borderRadius: "8px"
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <button
                            className="btn btn-secondary me-2"
                            onClick={() => navigate("/admin/carRentals")}
                        >
                            عودة للقائمة
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => navigate(createCarRental, { state: { row: carRental } })}
                        >
                            تعديل
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}