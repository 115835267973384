import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default function EditCarRental() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loader, showLoader, hideLoader] = useLoader();
    const [countries, setCountries] = useState([]);
    const [image, setImage] = useState(null);
    const [formData, setFormData] = useState({
        id: "",
        serviceId: "",
        nameAr: "",
        nameEng: "",
        descAr: "",
        descEng: "",
        logo: "",
        countryId: "",
        price: "",
        disntanc: "",
        startDay: null,
        endDay: null,
    });

    // Fetch car rental data
    useEffect(() => {
        const fetchCarRental = async () => {
            showLoader();
            try {
                const response = await fetch(baseurl + `car-rental/get-car-rental/${id}`, {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                const data = await response.json();
                if (data) {
                    setFormData({
                        ...data,
                        startDay: data.startDay ? new Date(data.startDay) : null,
                        endDay: data.endDay ? new Date(data.endDay) : null,
                    });
                    setImage(data.logo);
                }
            } catch (error) {
                console.error("Error fetching car rental:", error);
            } finally {
                hideLoader();
            }
        };

        if (id) {
            fetchCarRental();
        }
    }, [id, showLoader, hideLoader]);

    // Fetch countries
    const location = useLocation();
    const row = location.state.row;
    useEffect(() => {
        

        const getCountries = async () => {
            try {
                const response = await fetch(baseurl + "country/get-countries", {
                    method: "GET",
                });
                const json = await response.json();
                if (json && Array.isArray(json)) {
                    setCountries(json);
                }
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };
        getCountries();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDateChange = (field, value) => {
        if (value) {
            setFormData(prev => ({
                ...prev,
                [field]: value
            }));
        }
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await fetch(baseurl + "image/upload/image", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formData,
            });
            const data = await response.text();
            setImage(data);
            setFormData(prev => ({
                ...prev,
                logo: data
            }));
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        showLoader();

        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                if (key === 'startDay' || key === 'endDay') {
                    formDataToSend.append(key, formData[key]?.toISOString() || '');
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            });

            const response = await fetch(baseurl + "car-rental/update", {
                method: "PUT",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formDataToSend,
            });

            if (response.ok) {
                navigate("/admin/carRentals");
            } else {
                console.error("Error updating car rental");
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            hideLoader();
        }
    };

    return (
        <div>
            {loader}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">تعديل سيارة</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="/#">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                تعديل سيارة
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <hr />
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <TextField
                                    fullWidth
                                    label="الاسم بالعربي"
                                    name="nameAr"
                                    value={formData.nameAr}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextField
                                    fullWidth
                                    label="الاسم بالانجليزي"
                                    name="nameEng"
                                    value={formData.nameEng}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextField
                                    fullWidth
                                    label="الوصف بالعربي"
                                    name="descAr"
                                    value={formData.descAr}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextField
                                    fullWidth
                                    label="الوصف بالانجليزي"
                                    name="descEng"
                                    value={formData.descEng}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>الدولة</InputLabel>
                                    <Select
                                        name="countryId"
                                        value={formData.countryId}
                                        label="الدولة"
                                        onChange={handleInputChange}
                                        required
                                    >
                                        {countries.map((country) => (
                                            <MenuItem key={country.id} value={country.id}>
                                                {country.nameAr}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextField
                                    fullWidth
                                    label="السعر"
                                    name="price"
                                    type="number"
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextField
                                    fullWidth
                                    label="المسافة"
                                    name="disntanc"
                                    type="number"
                                    value={formData.disntanc}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <div className="mb-3">
                                        <DatePicker
                                            label="يوم البداية"
                                            value={formData.startDay}
                                            onChange={(newValue) => handleDateChange('startDay', newValue)}
                                            slotProps={{ 
                                                textField: { 
                                                    fullWidth: true,
                                                    sx: { mb: 2 }
                                                } 
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <DatePicker
                                            label="يوم النهاية"
                                            value={formData.endDay}
                                            onChange={(newValue) => handleDateChange('endDay', newValue)}
                                            slotProps={{ 
                                                textField: { 
                                                    fullWidth: true,
                                                    sx: { mb: 2 }
                                                } 
                                            }}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">صورة السيارة</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => uploadImage(e.target.files[0])}
                                        accept="image/*"
                                    />
                                </div>
                                {image && (
                                    <img
                                        src={baseurl + "/images/" + image}
                                        alt="Car preview"
                                        style={{
                                            maxWidth: "200px",
                                            maxHeight: "200px",
                                            objectFit: "cover",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">
                            حفظ التغييرات
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}